import React, { useState, useEffect } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import RoleEnum from 'common/enums/RoleEnum';
import { useSnackbar } from 'notistack';
import { pathOr } from 'rambda';
import { injectIntl } from 'react-intl';
import PageHeader from 'components-lib/PageHeader/PageHeader';
import IconCard from 'components-lib/IconCard/IconCard';
import Loading from "components-lib/Loading/Loading";
import Button from "components/CustomButtons/Button.js";
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import { colors, } from 'common/styles/configLayout';
import styles from "common/styles/widgets.js";
import { makeStyles } from '@material-ui/core/styles';

import LicenseDetailTable from './LicenseCards/LicenseDetailTable';
import MEMBER_LICENSE_REQUEST from 'queries/LicensesQueries/memberLicenseRequest';
import moment from "moment";
import PaymentCard from '../Payments/PaymentCards/PaymentCard';
import MEMBER_LICENSE_REQUEST_DOWNLOAD from 'queries/LicensesQueries/memberLicenseRequestDownload'
import MEMBER_LICENSE_REQUEST_CONFIRM from 'queries/LicensesQueries/memberLicenseRequestConfirm'
import MEMBER_LICENSE_REQUEST_CANCEL from 'queries/LicensesQueries/memberLicenseRequestCancel'
import { getApolloClient } from "configFiles/apollo";
import { changeLicensesNumberAction } from 'redux/actions';
import MEMBER_LICENSE_REQUESTS_TABLE from 'queries/LicensesQueries/memberLicenseRequestsTable';
import BlockIcon from '@material-ui/icons/Block';

import { connect } from 'react-redux';
import LicensePaymentTable from './LicenseCards/LicensePaymentTable';
import GetAppIcon from '@material-ui/icons/GetApp';
import CheckIcon from '@material-ui/icons/Check';

// css
import '../../assets/css/licenseDetailPage.css';

const useStyles = makeStyles((theme) => styles(theme))

const LicenseDetailPage = (props) => {
    const { intl, history, role, changeLicensesNumberAction } = props;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const client = getApolloClient(enqueueSnackbar, history, intl);
    const [licensesCount, setLicensesCount] = useState(null)
    const [licenseSummary, setLicenseSummary] = useState(null)
    const [disableButton, setDisableButton] = useState(false);

    const licenseId = (props?.match?.params?.licenseId) ? props?.match?.params?.licenseId : undefined
    const { loading, data: memberLicenseRequest, refetch: memberLicenseRefetch } = useQuery(MEMBER_LICENSE_REQUEST, { variables: { id: licenseId } });
    // const [getLicenseDownload, { data, error }] = useLazyQuery(MEMBER_LICENSE_DOWNLOAD);
    const [memberLicenseRequestDownload] = useMutation(MEMBER_LICENSE_REQUEST_DOWNLOAD)
    const [memberLicenseRequestConfirm] = useMutation(MEMBER_LICENSE_REQUEST_CONFIRM)
    const [memberLicenseRequestCancel] = useMutation(MEMBER_LICENSE_REQUEST_CANCEL)

    const numberOfCard = memberLicenseRequest?.memberLicenseRequest?.licenses?.length;

    useEffect(() => {
        console.log(memberLicenseRequest)
    }, [memberLicenseRequest]);

    const getLicensesNumber = async () => {
        try {
            const result = await client.query({
                query: MEMBER_LICENSE_REQUESTS_TABLE,
                variables: {
                    "filter": [
                        {
                            "status": {
                                "in": (role === RoleEnum.ADMIN) ? ["Ready", "Downloaded"] : ["Pending"]
                            }
                        }
                    ]
                }
            });
            if (result.error) {
                throw result.error;
            }
            if (result.errors) {
                throw result.errors[0];
            }
            changeLicensesNumberAction(pathOr('', ['data', 'memberLicenseRequestsTable', 'totalCount'], result));
        } catch (error) {
            throw error;
        }
    }

    /*const getLicenseSummary = async (year, clubId, licenses) => {
        if (licenses.length > 0) {
            try {
                const result = await client.query({
                    query: MEMBER_LICENSE_REQUEST_SUMMARY,
                    variables: {
                        input: {
                            year: year,
                            clubId: clubId,
                            licenses: licenses
                        }
                    }
                });
                if (result.error) {
                    throw result.error;
                }
                if (result.errors) {
                    throw result.errors[0];
                }
                setLicenseSummary(result?.data?.memberLicenseRequestSummary)
            } catch (error) {
                throw error;
            }
        }
    }*/


    useEffect(() => {
        setLicensesCount(Object.values(pathOr('', ['memberLicenseRequest', 'licenses'], memberLicenseRequest))?.map((x, index) => { return index })?.length);
        // getLicenseSummary(pathOr('', ['memberLicenseRequest', 'year'], memberLicenseRequest),
        //     pathOr('', ['memberLicenseRequest', 'club', 'id'], memberLicenseRequest),
        //     pathOr('', ['memberLicenseRequest', 'licenses'], memberLicenseRequest))
        //setStatus(Object.values(pathOr('', ['memberLicenseRequest', 'status'], memberLicenseRequest)));
    }, [memberLicenseRequest]);

    function downloadURI(url) {
        // var link = document.createElement("a");
        // link.download = url;
        // link.href = url;
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        window.open(url, '_blank');
    }

    const handleConfirmLicense = () => {
        setDisableButton(true);
        memberLicenseRequestConfirm({
            variables: {
                id: licenseId
            }
        }).then((response) => {
            enqueueSnackbar("Žiadosť o licencie bola potvrdená", { variant: 'success' });
            getLicensesNumber();
            memberLicenseRefetch();
            setDisableButton(false);
        }).catch((err) => {
            console.log('[Error]: ', err);
            setDisableButton(false);
        });
    }

    const handleDownloadLicense = () => {
        setDisableButton(true);
        memberLicenseRequestDownload({
            variables: {
                id: licenseId
            }
        }).then((response) => {
            enqueueSnackbar("Žiadosť o licencie vo formáte PDF bola stiahnutá", { variant: 'success' });
            downloadURI(response?.data?.memberLicenseRequestDownload?.url);
            memberLicenseRefetch();
            setDisableButton(false);
        }).catch((err) => {
            console.log('[Error]: ', err);
            setDisableButton(false);
        });
        
    }

    const handleDownloadPersonLicense = (url) => {
        downloadURI(url);
    }

    const handleCancelLicense = () => {
        setDisableButton(true);
        memberLicenseRequestCancel({
            variables: {
                id: licenseId
            }
        }).then((response) => {
            enqueueSnackbar("Žiadosť o licencie bola zrušená", { variant: 'success' });
            memberLicenseRefetch();
            setDisableButton(false);
        }).catch((err) => {
            console.log('[Error]: ', err);
            setDisableButton(false);
        });
    }



    const getActions = () => {
        let action = [];
        if (role === RoleEnum.ADMIN) {
            if ((pathOr('', ['memberLicenseRequest', 'status'], memberLicenseRequest) === "Downloaded"))
                action.push({
                    title: "Označiť ako vytlačené",
                    icon: <CheckIcon />,
                    disabled: disableButton,
                    onClick: () => handleConfirmLicense(),
                });
            if ((pathOr('', ['memberLicenseRequest', 'status'], memberLicenseRequest) === "Pending"))
                action.push({
                    title: "Zrušiť žiadosť",
                    icon: <BlockIcon />,
                    color: "warning",
                    disabled: disableButton,
                    onClick: () => handleCancelLicense(),
                })
        }
        return action;
    };

    const getStatus = (status) => {
        if (status === "Pending") return <Box style={{ color: colors.warning }} >Žiadosť o licencie (čaká na platbu)</Box>
        else if (status === "Paid") return <Box>Žiadosť o licencie (zaplatená)</Box>
        else if (status === "Ready") return <Box style={{ color: colors.warning }}>Žiadosť o licencie (čaká na výrobu)</Box>
        else if (status === "PartialReady") return <Box style={{ color: colors.warning }}>Žiadosť o licencie (čiastočne spracovaná)</Box>
        else if (status === "Downloaded") return <Box style={{ color: colors.secondary }}>Žiadosť o licencie (stiahnuté PDF)</Box>
        else if (status === "Completed") return <Box style={{ color: colors.success }}>Žiadosť o licencie (dokončená)</Box>
        else if (status === "Canceled") return <Box style={{ color: colors.error }}>Žiadosť o licencie (zrušená)</Box>
        else return <Box>{status}</Box>
    }

    const getBackground = (status) => {
        if (status === "Pending") return colors.warningBackground;
        else if (status === "Ready") return colors.warningBackground;
        else if (status === "PartialReady") return colors.warningBackground;
        else if (status === "Completed") return colors.successBackground;
        else if (status === "Downloaded") return colors.secondaryBackground;
        else if (status === "Canceled") return colors.errorBackground;
    }

    const getColor = (status) => {
        if (status === "Pending") return colors.warning;
        else if (status === "Ready") return colors.warning;
        else if (status === "PartialReady") return colors.warning;
        else if (status === "Downloaded") return colors.secondary;
        else if (status === "Completed") return colors.success;
        else if (status === "Canceled") return colors.error;
    }

    const handleBackAction = () => {
        history.goBack();
    };

    if (loading) return <Loading />;
    return (
        <Box className={'containerLicenseDetailPageHeader'} >

            <PageHeader
                title={getStatus(pathOr('', ['memberLicenseRequest', 'status'], memberLicenseRequest))}
                subTitle={getStatus(pathOr('', ['memberLicenseRequest', 'bid'], memberLicenseRequest))}
                actions={getActions()}
                withBackButton={true}
                handleBackAction={handleBackAction}
            />
            <Box className={'containerLicenseDetailPage'} >
                <Grid
                    className={'containerLicenseDetail'}
                >
                    <IconCard title={""} 
                        inside margin='0 0 0 0'    
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6} lg={4}>
                                <Box className={classes.readOnlyGrid}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" style={{ fontSize: "0.9em" }}>
                                                <span>Klub:</span>
                                                <Box className={classes.floatRight}>
                                                    <span style={{ fontSize: "1em", color: getColor(pathOr('', ['memberLicenseRequest', 'status'], memberLicenseRequest)) }} >{pathOr('', ['memberLicenseRequest', 'club', 'profile', 'name'], memberLicenseRequest)}</span>
                                                </Box>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" style={{ fontSize: "0.9em" }}>
                                                <span>Dátum podania:</span>
                                                <Box className={classes.floatRight}>
                                                    <span>{moment(pathOr('', ['memberLicenseRequest', 'createdAt'], memberLicenseRequest)).format("DD.MM.YYYY")}</span>
                                                </Box>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" style={{ fontSize: "0.9em" }}>
                                                <span>Počet licencií: </span>
                                                <Box className={classes.floatRight}>
                                                    <span>{licensesCount}</span>
                                                </Box>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={8}>
                                {((role === RoleEnum.ADMIN) && (pathOr('', ['memberLicenseRequest', 'status'], memberLicenseRequest) === "Ready" || pathOr('', ['memberLicenseRequest', 'status'], memberLicenseRequest) === "Downloaded" || pathOr('', ['memberLicenseRequest', 'status'], memberLicenseRequest) === "Completed")) &&
                                    <Box className={classes.floatRight}>
                                        <Button round table disabled={disableButton} color="primary" onClick={() => handleDownloadLicense()}><GetAppIcon /> Stiahnuť všetko</Button>
                                    </Box>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <LicenseDetailTable
                                    rows={pathOr('', ['memberLicenseRequest', 'licenses'], memberLicenseRequest)}
                                    role={role}
                                    status={pathOr('', ['memberLicenseRequest', 'status'], memberLicenseRequest)}
                                    defaultFilter={null}
                                    refetch={memberLicenseRefetch}
                                    history={history}
                                    onDownloadPersonLicense={handleDownloadPersonLicense}
                                />
                            </Grid>
                        </Grid>
                    </IconCard>

                </Grid>

                <Grid 
                    className={'containerLicensePayment'}
                    style={{ display: 'flex'}} 
                >
                    <Box 
                        style={{flex: 1}}
                        className={'licenseFees'}
                    >
                        <IconCard title={"Licenčné poplatky"} 
                            inside

                        >
                            <LicensePaymentTable
                                licenseSummary={pathOr('', ['memberLicenseRequest', 'summary'], memberLicenseRequest)}
                                numberOfCard={numberOfCard}
                            />
                        </IconCard>
                    </Box>
                    <Grid
                        className='licensePaymentCard'
                        style={{ marginTop: '-13px', flex: 1}}
                    >
                        <PaymentCard
                            data={pathOr('', ['memberLicenseRequest', 'payment'], memberLicenseRequest)}
                            detail="memberLicenseAwaiting"
                            history={history}
                            getLicensesNumber={getLicensesNumber()}
                            refetch={memberLicenseRefetch}
                        />
                    </Grid>
                </Grid>

            </Box>
        </Box>
    );
};

const mapStateToProps = (state) => ({
    role: state.role,
});

const mapDispatchToProps = (dispatch) => ({
    changeLicensesNumberAction: (value) => dispatch(changeLicensesNumberAction(value)),
});


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LicenseDetailPage));


