import React, { useState, useEffect, useRef } from "react";
import { Grid } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import Button from "components/CustomButtons/Button.js";

import { Formik } from "formik";
import { useSnackbar } from "notistack";
import Typography from '@material-ui/core/Typography';
import { colors, widgets } from "common/styles/configLayout.js";
import IconCard from 'components-lib/IconCard/IconCard';
import { useLocation } from 'react-router-dom';
import Modal from "components-lib/Modal/Modal";
import PageHeader from "components-lib/PageHeader/PageHeader";
import { useLazyQuery } from '@apollo/client';
import { connect } from 'react-redux';
import { changeLicensesNumberAction } from 'redux/actions';

import PriceList from './PriceList';
import { AntTabs, AntTab } from 'common/styles/muiDesign';
import styles from "common/styles/widgets.js";
import { makeStyles } from '@material-ui/core/styles';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import PRICING_UPDATE from 'queries/PricingQueries/pricingUpdate'
import { useMutation } from '@apollo/client';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import GroupIcon from '@material-ui/icons/Group';
import FilterListIcon from '@material-ui/icons/FilterList';

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { Box } from '@material-ui/core';

import { PRICING, CLUBS_PRICING, FUNCTIONS_PRICING, CATEGORIES_PRICING, OTHERS_PRICING } from 'queries/PricingQueries/pricing';

import useStylesTable from '../../components/ReactTable/styles';
const useStyles = makeStyles((theme) => styles(theme))

const PriceListPage = (props) => {
  const { intl, history, role, changeLicensesNumberAction } = props;
  let location = useLocation();
  const classesTable = useStylesTable();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const fetchedYears = React.useRef(new Set());

  const [refresh, setRefresh] = useState(false);
  
  const currentYear = new Date().getFullYear();
  const [ year, setYear ] = useState(currentYear - 1);
  const [ yearEdit, setYearEdit ] = useState(currentYear);
  const [ newYear, setNewYear ] = useState();

  const [fetchPriceList, { data: priceListData, refetch: refetchPriceList }] = useLazyQuery(PRICING);

  const [fetchClubs, { data: clubsData, refetch: refetchClubs }] = useLazyQuery(CLUBS_PRICING);
  const [fetchFunctions, { data: functionsData, refetch: refetchFunctions }] = useLazyQuery(FUNCTIONS_PRICING);
  const [fetchCategories, { data: categoriesData, refetch: refetchCategories }] = useLazyQuery(CATEGORIES_PRICING);
  const [fetchOthers, { data: othersData, refetch: refetchOthers }] = useLazyQuery(OTHERS_PRICING);
  
  const [fetchClubsEdit, { data: clubsDataEdit, refetch: refetchClubsEdit }] = useLazyQuery(CLUBS_PRICING);
  const [fetchFunctionsEdit, { data: functionsDataEdit, refetch: refetchFunctionsEdit }] = useLazyQuery(FUNCTIONS_PRICING);
  const [fetchCategoriesEdit, { data: categoriesDataEdit, refetch: refetchCategoriesEdit }] = useLazyQuery(CATEGORIES_PRICING);
  const [fetchOthersEdit, { data: othersDataEdit, refetch: refetchOthersEdit }] = useLazyQuery(OTHERS_PRICING);
  
  const [fetchClubsNextYear, { data: clubsDataNextYear, refetch: refetchClubsNextYear }] = useLazyQuery(CLUBS_PRICING);
  const [fetchFunctionsEditNextYear, { data: functionsDataEditNextYear, refetch: refetchFunctionsEditNextYear }] = useLazyQuery(FUNCTIONS_PRICING);
  const [fetchCategoriesEditNextYear, { data: categoriesDataEditNextYear, refetch: refetchCategoriesEditNextYear }] = useLazyQuery(CATEGORIES_PRICING);
  const [fetchOthersEditNextYear, { data: othersDataEditNextYear, refetch: refetchOthersEditNextYear }] = useLazyQuery(OTHERS_PRICING);
  
  const [tab, setTab] = useState(0);
  
  const [newYearData, setNewYearData] = useState();
  const [hasFetchedClubs, setHasFetchedClubs] = useState(false);
  const [hasFetchedClubsEdit, setHasFetchedClubsEdit] = useState(false);
  const [hasFetchedFunctions, setHasFetchedFunctions] = useState(false);
  const [hasFetchedFunctionsEdit, setHasFetchedFunctionsEdit] = useState(false);
  const [hasFetchedCategories, setHasFetchedCategories] = useState(false);
  const [hasFetchedCategoriesEdit, setHasFetchedCategoriesEdit] = useState(false);
  const [hasFetchedOthers, setHasFetchedOthers] = useState(false);
  const [hasFetchedOthersEdit, setHasFetchedOthersEdit] = useState(false);
  const [hasFetchedNextYear, setHasFetchedNextYear] = useState(false);

  const [initialValuesClubs, setInitialValuesClubs] = React.useState({
    dataOld: {},
    dataEdit: {},
  });
  const [initialValuesFunctions, setInitialValuesFunctions] = React.useState({
    dataOld: {},
    dataEdit: {},
  });
  const [initialValuesCategories, setInitialValuesCategories] = React.useState({
    dataOld: {},
    dataEdit: {},
  });
  const [initialValuesOthers, setInitialValuesOthers] = React.useState({
    dataOld: {},
    dataEdit: {},
  });

  const combinedInitialValues = {
    clubs: initialValuesClubs,
    functions: initialValuesFunctions,
    categories: initialValuesCategories,
    others: initialValuesOthers,
  };

  const newYearOptions = Array.from({ length: 3 }, (_, index) => {
    const year = currentYear - index;
    return { label: String(year), value: String(year) };
  });

  // Trigger fetching based on the active tab, and track fetch status
  React.useEffect(() => {
    if (tab === 0) {
      if ( !hasFetchedClubs ) fetchClubs({ variables: { year } }); setHasFetchedClubs(true);
      if ( !hasFetchedClubsEdit ) fetchClubsEdit({ variables: { year: yearEdit } }); setHasFetchedClubsEdit(true);
      if ( !hasFetchedNextYear ) fetchClubsNextYear({ variables: { year: (yearEdit + 1) } }); setHasFetchedNextYear(true);
    } else if (tab === 1) {
      if ( !hasFetchedFunctions ) fetchFunctions({ variables: { year } }); setHasFetchedFunctions(true);
      if ( !hasFetchedFunctionsEdit ) fetchFunctionsEdit({ variables: { year: yearEdit } }); setHasFetchedFunctionsEdit(true); 
    } else if (tab === 2) {
      if ( !hasFetchedCategories ) fetchCategories({ variables: { year } }); setHasFetchedCategories(true);
      if ( !hasFetchedCategoriesEdit ) fetchCategoriesEdit({ variables: { year: yearEdit } }); setHasFetchedCategoriesEdit(true); 
    } else if (tab === 3 && !hasFetchedOthers) {
      if ( !hasFetchedOthers ) fetchOthers({ variables: { year } }); setHasFetchedOthers({ ...hasFetchedOthers, dataOld: true });
      if ( !hasFetchedOthersEdit ) fetchOthersEdit({ variables: { year: yearEdit } }); setHasFetchedOthersEdit(true); 
    }
  }, [tab, year, yearEdit, fetchClubs, fetchFunctions, fetchCategories, fetchOthers, fetchClubsEdit, fetchFunctionsEdit, fetchCategoriesEdit, fetchOthersEdit]);
 
  // Update initialValues only when the specific data is available
  React.useEffect(() => {
    if (tab === 0 && clubsData && clubsDataEdit) {
      setInitialValuesClubs({
        dataOld: clubsData,
        dataEdit: clubsDataEdit,
      });
    } else if (tab === 1 && functionsData && functionsDataEdit) {
      setInitialValuesFunctions({
        dataOld: functionsData,
        dataEdit: functionsDataEdit,
      });
    } else if (tab === 2 && categoriesData && categoriesDataEdit) {
      setInitialValuesCategories({
        dataOld: categoriesData,
        dataEdit: categoriesDataEdit,
      });
    } else if (tab === 3 && othersData && othersDataEdit) {
      setInitialValuesOthers({
        dataOld: othersData,
        dataEdit: othersDataEdit,
      });
    }
  }, [tab, clubsData, clubsDataEdit, functionsData, functionsDataEdit, categoriesData, categoriesDataEdit, othersData, othersDataEdit]);

  const handleInitialValue = () => {
    if (tab === 0) {
      return initialValuesClubs;
    } else if (tab === 1) {
      return initialValuesFunctions;
    } else if (tab === 2) {
      return initialValuesCategories;
    } else if (tab === 3) {
      return initialValuesOthers;
    }
  }

  const [pricingUpdate, { loading, error }] = useMutation(PRICING_UPDATE);

  const [modalOpen, setModalOpen] = useState(false);
  const [editPrice, setEditPrice] = useState(false);
  const [expandedGroup, setExpandedGroup] = useState(null);
  const [expandedGroupEdit, setExpandedGroupEdit] = useState(null);

  const [optionsEdit, setOptionsEdit] = React.useState([]);

  React.useEffect(() => {
    // Ensure `clubsDataNextYear` is fully loaded before calculating options
    if (clubsDataNextYear?.pricing?.clubs) {
      const newOptions = areAllLicenseValuesNullOrZero(clubsDataNextYear.pricing.clubs)
        ? [{ label: String(currentYear), value: String(currentYear) }]
        : Array.from({ length: 2 }, (_, index) => ({
          label: String(currentYear + index),
          value: String(currentYear + index)
        }));

        
        setOptionsEdit(newOptions);
      }
  }, [clubsDataNextYear, currentYear]);

  function areAllLicenseValuesNullOrZero(categories) {
    const nonNullValues = []; // Collect non-null, non-zero values

    categories.forEach(category => {
        if (category.license) {
            // Filter out `__typename` and only check other keys
            Object.entries(category.license).forEach(([key, value]) => {
                if (key !== '__typename' && value !== null) {
                    nonNullValues.push(value);
                }
            });
        }
    });

    // Log any values in license that are not null or 0, excluding __typename
    if (nonNullValues.length > 0) {
      // console.log("Non-null, non-zero values in license (excluding __typename):", nonNullValues);
      return false;
    }

    // Return true if all license values (excluding __typename) are null or 0, false otherwise
    return nonNullValues.length === 0;
  }

  const handleHasFetched = (type) => {
    if (type === 'old') {
      setHasFetchedClubs(false);
      setHasFetchedFunctions(false);
      setHasFetchedCategories(false);
      setHasFetchedOthers(false);
    } else {
      setHasFetchedClubsEdit(false);
      setHasFetchedFunctionsEdit(false);
      setHasFetchedCategoriesEdit(false);
      setHasFetchedOthersEdit(false);
    }
  };


  const handleYearChange = (newYear) => {
    setYear(newYear);
    handleHasFetched('old');
  };

  const handleYearEditChange = (newYearEdit) => {
    setYearEdit(newYearEdit);
    handleHasFetched();
  };

  const handleNewYearChange = (newYear) => {
    setNewYear(newYear);
  };

  useEffect(() => {
    if (newYear) {
      fetchPriceList({ variables: { year: newYear } });
    }
  }, [newYear]);
  
  useEffect(() => {
    if (priceListData) {
      setNewYearData(priceListData);
    }
  }, [priceListData]);  
  

  const handleChangeTab = async (event, newValue, formikProps) => {
    if ( editPrice === true ) {
      setEditPrice(false)
      formikProps.resetForm();
    }
    setExpandedGroupEdit(null);
    setExpandedGroup(null)

    setTab(newValue);
  };

  const getChangedOthers = (initialOthers, currentOthers) => {
      // Check if the values differ
      if (initialOthers.licenseCardIssueFee !== currentOthers.licenseCardIssueFee) {
          // Return the changed value if they are different
          return currentOthers.licenseCardIssueFee;
      }
      // Return [] if there is no change
      return [];
  };

  const getChangedCategories = (initialCategories, currentCategories) => {

    return currentCategories.filter((currentCategory, index) => {
        const initialCategory = initialCategories[index];

        return (
            currentCategory.license.standardFee !== initialCategory.license.standardFee ||
            currentCategory.license.uciFee !== initialCategory.license.uciFee ||
            currentCategory.license.transferFee !== initialCategory.license.transferFee ||
            currentCategory.license.expressFee !== initialCategory.license.expressFee
        );
    });
  };

  const getChangedFunctions = (initialFunctions, currentFunctions) => {
    return currentFunctions.filter((currentFunction, index) => {
      const initialFunction = initialFunctions[index];

      return (
        currentFunction.license.fee !== initialFunction.license.fee ||
        currentFunction.license.expressFee !== initialFunction.license.expressFee
      );
    });
  };
  
  const getChangedClubs = (initialClubs, currentClubs) => {
    return currentClubs.filter((currentClub, index) => {
      const initialClub = initialClubs[index];
      
      return (
        currentClub.license.renewFee !== initialClub.license.renewFee ||
        currentClub.license.fee !== initialClub.license.fee
      );
    });
  };

  const handleUpdate = async (body) => {

    let input = {};

    if (tab === 3) {
      const changedOthers = getChangedOthers(
        initialValuesOthers.dataEdit.pricing.others, body.others.dataEdit.pricing.others
      );
      input = {
        others: {},
      };

      if (changedOthers.length !== 0) {
        input.others.licenseCardIssueFee = parseFloat(changedOthers);
      }      
      
      if (changedOthers.length === 0) {
        enqueueSnackbar(
          intl.formatMessage(
            { id: "priceList.snackbar.nothingChanged.error" },
            { priceListType: intl.formatMessage({ id: "priceList.others" }) }
          ), { variant: 'error' } );
        return;
      }

    } else if (tab === 2) {
      const changedCategories = getChangedCategories(
        initialValuesCategories.dataEdit.pricing.categories, body.categories.dataEdit.pricing.categories
      );
      
      input = {
        categories: changedCategories.map(({ id, license }) => ({
          id,
          license: {
            standardFee: license.standardFee ? parseFloat(license.standardFee) : 0,
            uciFee: license.uciFee ? parseFloat(license.uciFee) : 0,
            transferFee: license.transferFee ? parseFloat(license.transferFee) : 0,
            expressFee: license.expressFee ? parseFloat(license.expressFee) : 0,
          },
        })),
      };

      if ((input.categories).length === 0) {
        enqueueSnackbar(
          intl.formatMessage(
            { id: "priceList.snackbar.nothingChanged.error" },
            { priceListType: intl.formatMessage({ id: "priceList.categories" }) }
          ), { variant: 'error' } );
        return;
      }
      
    } else if (tab === 1) {
      const changedFunctions = getChangedFunctions(
        initialValuesFunctions.dataEdit.pricing.functions, body.functions.dataEdit.pricing.functions
      );

      input = {
        functions: changedFunctions.map(({ id, license }) => ({
          id,
          license: {
            fee: license.fee ? parseFloat(license.fee) : 0,
            expressFee: license.expressFee ? parseFloat(license.expressFee) : 0,
          },
        })),
      };

      if ((input.functions).length === 0) {
        enqueueSnackbar(
          intl.formatMessage(
            { id: "priceList.snackbar.nothingChanged.error" },
            { priceListType: intl.formatMessage({ id: "priceList.functions" }) }
          ), { variant: 'error' } );
        return;
      }

    } else if (tab === 0) {
      const changedClubs = getChangedClubs(initialValuesClubs.dataEdit.pricing.clubs, body.clubs.dataEdit.pricing.clubs);

      // const changedClubs = initialValuesClubs.dataEdit.pricing.clubs;
      input = { 
        clubs: changedClubs.map(({ type, license }) => ({
          type,
          license: {
            // fee: 0,
            // renewFee: 0,
            fee: license.fee ? parseFloat(license.fee) : 0,
            renewFee: license.renewFee ? parseFloat(license.renewFee) : 0,
          },
        })),
      }

      if ((input.clubs).length === 0) {
        enqueueSnackbar(
          intl.formatMessage(
            { id: "priceList.snackbar.nothingChanged.error" },
            { priceListType: intl.formatMessage({ id: "priceList.clubs" }) }
          ), { variant: 'error' } );
        return;
      }
    }    
    
    try {
        const response = await pricingUpdate({
          variables: {
            year: yearEdit,
            input: input,
        },
      });
    
      setEditPrice(false)
      setRefresh(true);
      enqueueSnackbar(intl.formatMessage({ id: "priceList.snackbar.success" }), { variant: 'success' });
    } catch (err) {
      console.error('[Error]:', err);
      enqueueSnackbar(intl.formatMessage({ id: "priceList.snackbar.error" }), { variant: 'error' });
    }        
  }

  const getActions = (formikProps) => {
    let actions = [
      {
        title: editPrice === true ? "Zmeniť poplatky" : "Upraviť poplatky",
        color: editPrice === true ? "success" : "info",
        onClick: () => {
          if (editPrice === true) {
            formikProps.handleSubmit();
          } else {
            setEditPrice(true)
          }
        },
      },
    ];

    if (editPrice === true) {
        actions.unshift({
          title: "Zrušiť",
          onClick: () => {
            formikProps.resetForm({
              values: {
                ...formikProps.values,
                dataEdit: handleInitialValue().dataEdit,
              },
            });
            setEditPrice(false);
          },
        });
    } 
    else if ( !optionsEdit.some((item) => item.value === (currentYear + 1).toString()) ) {
      actions.unshift({
        title: "Vytvoriť",
        onClick: () => {
          setModalOpen(true);
          setNewYear(currentYear);
        },
      });
    }

    return actions;
  };


  const renderModalButtons = (formikProps) => {
    return (
      <>
        <Button
          disabled={false}
          onClick={() => handleCreatePriceList()}
          color="primary"
          round
          table
          size="sm">
          Vytvoriť cenník
        </Button>
      </>
    );
  };

  const handleCreatePriceList = async () => {

    let input = {
      others: {
        licenseCardIssueFee: newYearData.pricing.others.licenseCardIssueFee,
      },
      categories: newYearData.pricing.categories.map(({ id, license }) => ({
        id,
        license: {
          standardFee: license.standardFee,
          uciFee: license.uciFee,
          transferFee: license.transferFee,
          expressFee: license.expressFee,
        },
      })),
      functions: newYearData.pricing.functions.map(({ id, license }) => ({
        id,
        license: {
          fee: license.fee,
          expressFee: license.expressFee,
        },
      })),
      clubs: newYearData.pricing.clubs.map(({ type, license }) => ({
        type,
        license: {
          fee: license.fee,
          renewFee: license.renewFee,
        },
      })),
    }

    const nextYear = currentYear + 1;

    try {
      const response = await pricingUpdate({
        variables: {
          year: nextYear,
          input: input,
        },
      });
      fetchClubsNextYear({ variables: { year: (nextYear) } });
      setModalOpen(false);
      setYear(currentYear);
      setYearEdit(nextYear);
      enqueueSnackbar(intl.formatMessage({ id: "priceList.snackbar.newPriceList.success" }, { nextYear }), { variant: 'success' });
    } catch (err) {
      console.error('[Error]:', err);
      enqueueSnackbar(intl.formatMessage({ id: "priceList.snackbar.error" }), { variant: 'error' });
    }
  }

  const renderTables = (formikProps) => {
    return (
      <div>
        <PriceList
          formikProps={formikProps}
          editPrice={editPrice}
          currentYear={currentYear}
          tab={tab}
          setEditPrice={setEditPrice}
          intl={intl}
          handleYearChange={handleYearChange}
          handleYearEditChange={handleYearEditChange}
          year={year}
          yearEdit={yearEdit}
          expandedGroup={expandedGroup}
          expandedGroupEdit={expandedGroupEdit}
          setExpandedGroup={setExpandedGroup}
          setExpandedGroupEdit={setExpandedGroupEdit}
          optionsEdit={optionsEdit}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </div>
    );
  };

  // if (loadingOld || loadingEdit) return <Loading />;

  return (
    <div 
      style={{
        width: 'auto',
        maxWidth: '100rem',
        }} 
    >
      <Formik
        initialValues={combinedInitialValues}
        enableReinitialize={true} 
        onSubmit={(values) => {
          handleUpdate(values);
        }}
      >
        {formikProps => {

          return (
            <>
              <Grid container style={{
                // minWidth:'10rem',
                // minWidth: tab === 0 ? "30rem" : tab === 1 ? "30rem" : tab === 2 ? "40rem" : "100%",
                // maxWidth:'80rem',
                width:"auto",
               }}>
                <Grid
                  style={{
                    width: "100%",
                  }}
                >
                  <PageHeader
                    actions={getActions(formikProps)}
                    withBackButton={false}
                    // headerWidth= "100%"
                    tabs={
                      <AntTabs
                        onChange={(event, newValue) => handleChangeTab(event, newValue, formikProps)}
                        value={tab}
                        aria-label="Tabs where each tab needs to be selected manually"
                        variant="scrollable"
                      >
                        <AntTab label={<Typography variant="body1" >
                          <AssignmentIndIcon className={classes.tabsIcon} />
                          {intl.formatMessage({ id: "priceList.clubs" })}</Typography>} 
                        />
                        <AntTab label={<Typography variant="body1" >
                          <DirectionsBikeIcon className={classes.tabsIcon} />
                          {intl.formatMessage({ id: "priceList.functions" })}</Typography>} 
                        />
                        <AntTab label={<Typography variant="body1" >
                          <GroupIcon className={classes.tabsIcon} /> 
                          {intl.formatMessage({ id: "priceList.categories" })}</Typography>} 
                        />
                        <AntTab label={<Typography variant="body1" >
                          <FilterListIcon className={classes.tabsIcon} /> 
                          {intl.formatMessage({ id: "priceList.others" })}</Typography>} 
                        />
                      </AntTabs>
                    }
                  />

                </Grid>
                <Box className="priceListPageContainer"
                  style={{
                    width: '100%',
                  }}
                >
                  <IconCard
                    padding={'16px'}
                    style={{
                      width: '100%',
                      display: 'flex',
                  }}
                    >
                    {renderTables(formikProps)}
                  </IconCard>
                </Box>
              </Grid>
            </>
          )
        }}
      </Formik>
      
      <Modal
        title={"Vytvoriť cenníky na nasledujúci rok"}
        open={modalOpen}
        onClose={() => {setModalOpen(false); setNewYear(null)}}
        actions={renderModalButtons()}
        fullWidth
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography
              variant="body1" 
              style={{ color: colors.primary, paddingBottom: "1rem" }}
            >Vyberte rok, podľa ktorého sa skopírujú hodnoty</Typography>
            <FormControl size="small" className={classesTable.root} style={{ width: "8rem" }} fullWidth variant="outlined">
              <Select
                MenuProps={{ classes: { paper: classesTable.select } }}
                style={{
                  // backgroundColor: '#364759',
                  // borderRadius: '10px',
                  // borderColor: 'rgba(60, 72, 88, 0.4)',
                  // color: '#F5F6F6',
                  fontSize: '0.8rem',
                }}
                value={newYear ? newYear : currentYear}
                onChange={(e) => {
                handleNewYearChange(e.target.value);
                }}
                inputProps={{
                    classes: { icon: classesTable.selectIcon },
                }}
              >
                  {newYearOptions.map((option, index) => (
                  <MenuItem
                      key={`filter.${index}`}
                      value={option.value}
                      className="inputSelect"
                      classes={{
                      root: classesTable.selectMenuItem,
                      selected: classesTable.selectMenuItemSelected,
                      }}
                  >
                      {option.label}
                  </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {/* {options.map((option, index) => {

              return (
                <MenuItem
                    key={`filter.${index}`}
                    value={option.value}
                    className="inputSelect"
                    classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                    }}
                >
                    {option.label}
                </MenuItem>

              )
            })} */}
          {/* </Select> */}
        </Grid>
        </Grid>

      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  role: state.role,
});

const mapDispatchToProps = (dispatch) => ({
  changeLicensesNumberAction: (value) => dispatch(changeLicensesNumberAction(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PriceListPage));

