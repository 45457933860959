import React from "react";
import PropTypes from "prop-types";
import Refresh from "@material-ui/icons/Refresh";
import ReactTable from "components/ReactTable/ReactTable.js";
import { pathOr } from "rambda";
import Button from "components/CustomButtons/Button.js";
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
const QueryTable = (props) => {
  const {
    nameTable,
    columns = [],
    queryVariables = {},
    queryDataPath = [""],
    showRefreshButton = false,
    defaultSort = null,
    permanentFilter = null,
    history,
    openCollapse,
    idCollapse,
    columnsWidth,
    selectedIndex,
    index,
    data,
    loadData,
    filterView = true,
    paginateView = true,
    numberOfRows = 25,
    toggle,
    changeTab,
    setChangeTab,
  } = props;


  const getPosition = (string, subString, index) => {
    return string.split(subString, index).join(subString).length;
  };

  //filters
  const parseQueryFilters = () => {
    const initQueryParams = history.location.search;
    const filtersRaw = initQueryParams.split("filter");
    const result = {};

    filtersRaw.map((filterRaw, index) => {
      if (index === 0) {
        return null;
      }

      const fieldName = filterRaw.substring(
        filterRaw.indexOf("[") + 1,
        filterRaw.indexOf("]")
      );

      const filterType = filterRaw.substring(
        getPosition(filterRaw, "[", 2) + 1,
        getPosition(filterRaw, "]", 2)
      );


      let filterValue = "";
      let startFilterValue = "";
      let endFilterValue = "";

      if (filterRaw.indexOf("&") > 0) {
        filterValue = filterRaw.substring(
          filterRaw.indexOf("=") + 1,
          filterRaw.indexOf("&")
        );
      } else {
        filterValue = filterRaw.substring(
          filterRaw.indexOf("=") + 1,
          filterRaw.length
        );
      }

      if (filterRaw.indexOf("[start]") > 0) {
        filterValue = filterRaw.substring(
          filterRaw.indexOf("=") + 1,
          filterRaw.length
        );
        startFilterValue = filterValue.substring(0, 10);
        endFilterValue = filterValue.substring(11, 21);
      }
      if (filterType !== "between") filterValue = decodeURIComponent(filterValue);
      
      if (!isNaN(filterValue)) {
        filterValue = parseInt(filterValue);
      }


      if (filterType === "contains") 
        result[fieldName] = { contains: String(filterValue) }

      //MILAN START 
      else if (filterType === "between") result[fieldName] = {
        between: {
          start: new Date(startFilterValue),
          end: new Date(endFilterValue)
        }

      }
      //MILAN END

      else result[fieldName] = { eq: filterValue }
      return "";
    });

    return result;
  };

  //sorts
  const parseQuerySorts = () => {
    const initQueryParams = history.location.search;
    const sortsRaw = initQueryParams.split("sort");
    const result = [];

    sortsRaw.map((sortRaw, index) => {
      if (index === 0) {
        return null;
      }

      const fieldName = sortRaw.substring(
        sortRaw.indexOf("[") + 1,
        sortRaw.indexOf("]")
      );

      let sortValue = "";
      if (sortRaw.indexOf("&") > 0) {
        sortValue = sortRaw.substring(
          sortRaw.indexOf("=") + 1,
          sortRaw.indexOf("&")
        );
      } else {
        sortValue = sortRaw.substring(sortRaw.indexOf("=") + 1, sortRaw.length);
      }

      result.push({ field: fieldName, order: sortValue });
      return "";
    });

    return result;
  };

  //page
  const parseQueryPage = () => {
    const queryParams = new URLSearchParams(history.location.search);
    const pageNumber = queryParams.get('pageNum');
    return pageNumber ? parseInt((pageNumber - 1), 10) : 0;
  };

  const initFilters = (toggle) 
    ? null
     : parseQueryFilters(); //TODO
  const initSorts = parseQuerySorts();

  const [initPageNumber, setInitPageNumber] = useState(toggle ? 1 : parseQueryPage());

  const storedRowLimit = parseInt(localStorage.getItem(nameTable));
  const initialRowLimit = storedRowLimit && !isNaN(storedRowLimit) ? storedRowLimit : numberOfRows;

  const [pageNumber, setPageNumber] = useState(initPageNumber);
  const [rowsCount, setRowsCount] = useState(initialRowLimit);
  const [refreshHelper, toggleRefreshHelper] = useState(initialRowLimit);
  const [filters, setFilters] = useState({ ...initFilters });
  const [sorts, setSorts] = useState(initSorts);

  useEffect(() => {
    if (changeTab) {
      setFilters({});
      setInitPageNumber(0);
      setPageNumber(0);
      setChangeTab(false)
    }
  }, [changeTab])


  const generateQueryParams = () => {
    const querySorts = sorts
      .map((sort) => `sort[${sort["field"]}]=` + sort["order"])
      .join("&");

    const queryFilters = Object.keys(filters)
      .map((key) => {
        if (filters[key]["contains"]) {
          return (
            `filter[${key}][contains]=` +
            encodeURIComponent(filters[key]["contains"])
          );
        } else if (filters[key]["eq"]) {
          return `filter[${key}][eq]=` + encodeURIComponent(filters[key]["eq"]);
        }

        //MILAN START 
        else if (filters[key]["between"]) {
          return `filter[${key}][between][start]=` + encodeURIComponent(filters[key]["between"]["start"]).substring(0, 10) + `|` + encodeURIComponent(filters[key]["between"]["end"]).substring(0, 10);
        }
        //MILAN END 
        return "";
      })
      .join("&");

    const queryPagination = `pageNum=${pageNumber +1}`;

    if (!queryFilters && !querySorts) {
      return queryPagination;
    } else if (queryFilters && !querySorts) {
      return `${queryFilters}&${queryPagination}`;
    } else if (!queryFilters && querySorts) {
      return `${querySorts}&${queryPagination}`;
    } else if (queryFilters && querySorts) {
      return `${queryFilters}&${querySorts}&${queryPagination}`;
    }
  };

  useEffect(() => {
    history.replace({
      pathname: history.location.pathname,
      search: generateQueryParams(),
    });

    loadData({
      variables: {
        ...queryVariables,
        offset: pageNumber * rowsCount,
        limit: rowsCount,
        filter: {
          ...permanentFilter,
          ...filters,
        },
        sort: sorts.length > 0 ? sorts : defaultSort ? defaultSort : [],
      },
    });
  }, [pageNumber, rowsCount, filters, refreshHelper, sorts]);

  const setEndOfDay = (date) => {
    const newDate = new Date(date);
    newDate.setHours(23, 59, 59, 999);
    return newDate;
  };
  

  const handleFilterChange = (filterKey, value, valueEnd, dataType, operator) => {
    let newFilters = { ...filters };

    if (dataType === "number" && isNaN(value)) {
      return;
    }

    if (!value) {
      if (Array.isArray(filterKey)) {
        filterKey.map((key) => delete newFilters[key]);
      } else {
        delete newFilters[filterKey];
      }
    } else {
      if (Array.isArray(filterKey)) {
        filterKey.map((key) => {
          if (dataType === "number") {
            newFilters[filterKey] = {};
            newFilters[key][operator] = parseInt(value);
          }

          //MILAN START 
          else if (dataType === "dateBetween") {
            const startDate = new Date(value);
            startDate.setHours(0, 0, 0, 0); 

            const endDate = new Date(valueEnd);
            endDate.setHours(23, 59, 59, 999);

            newFilters[filterKey] = {
              between: {
                start: ((value === " ") || (value === null)) ? "2018-01-01" : startDate,
                end: ((valueEnd === " ") || (valueEnd === null)) ? "2100-01-01" : endDate
              }

            }
          }
          //MILAN END 

          else {
            newFilters[filterKey] = {};
            newFilters[key][operator] = value;
          }
          return "";
        });
      }
      else {
        if (dataType === "number") {
          newFilters[filterKey] = {};
          newFilters[filterKey][operator] = parseInt(value);
        }

        //MILAN START 
        else if (dataType === "dateBetween") {
          const startDate = new Date(value);
          startDate.setHours(0, 0, 0, 0); 

          const endDate = new Date(valueEnd);
          endDate.setHours(23, 59, 59, 999);

          newFilters[filterKey] = {
            between: {
              start: ((value === " ") || (value === null)) ? "2018-01-01" : startDate,
              end: ((valueEnd === " ") || (valueEnd === null)) ? "2100-01-01" : endDate
            }
          }

        }
        //MILAN END 

        else {
          newFilters[filterKey] = {};
          newFilters[filterKey][operator] = value;
        }
      }
    }

    setFilters(newFilters);
  };

  const handleSortChange = (sortKey, canSort) => {
    const newSorts = [...sorts];
    if (!canSort) {
      return;
    }

    if (newSorts.length > 0) {
      if (newSorts[0].field === sortKey) {
        if (newSorts[0].order === "ASC") {
          newSorts[0].order = "DESC";
        } else if (newSorts[0].order === "DESC") {
          newSorts.splice(0, 1);
        }
      } else {
        newSorts.splice(0, 1);
        newSorts.push({
          field: sortKey,
          order: "ASC",
        });
      }
    } else {
      newSorts.push({
        field: sortKey,
        order: "ASC",
      });
    }

    setSorts(newSorts);
  };

  const onPageChange = (page) => {
    setPageNumber(page);
  };

  const onRowsCountChange = (rowsCount) => {
    setRowsCount(rowsCount);
    localStorage.setItem(nameTable, rowsCount.toString());
  };

  const tableData = pathOr([], queryDataPath, data);
  const totalCount = pathOr(0, [queryDataPath[0], "totalCount"], data);


  return (
    <>
      {showRefreshButton && (
        <div style={{ display: "flex" }}>
          <Button
            style={{ marginLeft: "auto" }}
            size="sm"
            onClick={() => toggleRefreshHelper(!refreshHelper)}
            color="primary"
          >
            <Refresh />
          </Button>
        </div>
      )}
      <ReactTable
        columns={columns}
        columnsWidth={columnsWidth}
        data={tableData}
        filters={filters}
        filterView={true}
        paginateView={paginateView}
        handleFilterChange={handleFilterChange}
        sorts={sorts}
        handleSortChange={handleSortChange}
        currentPage={pageNumber}
        rowsPerPage={rowsCount}
        totalCount={totalCount}
        onPageChange={(page) => {
          onPageChange(page);
        }}
        onRowsCountChange={(rows) => {
          onRowsCountChange(rows);
        }}
        numberOfRows={rowsCount}
        openCollapse={openCollapse}
        idCollapse={idCollapse}
        selectedIndex={selectedIndex}
        index={index}
      />
    </>
  );
};

QueryTable.propTypes = {
  query: PropTypes.any,
};

export default withRouter(QueryTable);
