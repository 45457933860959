import React, { useState, useEffect, useRef } from 'react';
import { Grid, Typography } from '@material-ui/core';
import Button from "components/CustomButtons/Button.js";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from "@material-ui/core/styles";
import styles from './styles';
import { injectIntl } from 'react-intl';
import '../../assets/css/pageHeader.css';

const PageHeader = ({ 
    headerWidth, 
    withBackButton = true, 
    disabled, 
    title = '', 
    actions = [], 
    handleBackAction, 
    subTitle = '',
    intl, 
    tabs,
    dropDownBtn = [],
    toggleExportButtons }) => {
    const useStyles = makeStyles(styles);
    const classes = useStyles();

    const dropdownRef = useRef(null);

    const actionsArray = (actions) ? actions.length : 0

    const baseFreeCols = withBackButton ? 11 : 12;
    const baseFreeColsLg = withBackButton ? 11 : 12;

    const actionsGridSizeXl = actionsArray;
    const titleGridSizeXl = baseFreeCols - actionsArray;

    const actionsGridSizeLg = actionsArray + 2;
    const titleGridSizeLg = baseFreeColsLg - (actionsArray + 2);

    const actionsGridSizeMd = actionsArray + 3;
    const titleGridSizeMd = baseFreeCols - (actionsArray + 3);
    const [visibleDropdown, setVisibleDropdown] = useState(null);
    const [isClosing, setIsClosing] = useState(false);

    const toggleDropdown = (index) => {
        // setVisibleDropdown(visibleDropdown === index ? null : index);
        if ( visibleDropdown === index ) {
            setIsClosing(true);
            setTimeout(() => {
                setVisibleDropdown(null);
            }, 200);
        } else {
            setVisibleDropdown(index);
            setIsClosing(false);
        }
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            // setVisibleDropdown(null);
            setIsClosing(true);
            setTimeout(() => {
                setVisibleDropdown(null);
            }, 200);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <Grid container spacing={3}
            style={{ marginBottom: "-26px", display: "flex",
                justifyContent: tabs ? "space-between" : "flex-end", 
                width: headerWidth,
            }}
        >
            {!title ? 
                <>
                    {/* {withBackButton && (
                        <Grid item lg={1} md={2} sm={2} xs={4} style={{ marginTop: "-13px", marginLeft: "-10px" }}>
                            <Button size="sm" color="facebook" round onClick={(e) => handleBackAction()}>
                                <ArrowBackIcon className={classes.backIcon} />
                                {intl.formatMessage({ id: 'pageHeader.back' })}
                            </Button>
                        </Grid>
                    )} */}

                    { tabs && (
                        <Grid
                            style={{
                                display: 'flex', justifyContent: 'flex-end',
                                width: "auto", maxWidth: "100%",
                                padding: '0.75rem', paddingTop: '0',
                            }}
                        >
                            <Grid item 
                                style={{
                                    position: "relative",
                                    display: "inline-block",
                                    flexWrap: 'nowrap',
                                    gap: '5px',
                                    width: 'auto',
                                    overflowX: 'scroll',
                                    whiteSpace: 'nowrap',
                                    scrollbarWidth: 'none',
                                }}
                            >{tabs}</Grid>
                        </Grid>
                    )}
                    
                        <Grid item 
                            ref={dropdownRef}
                            style={{
                                display: 'flex', justifyContent: 'flex-end',
                                width: "auto", maxWidth: "100%",
                                paddingTop: !tabs ? "0px" : '4px',
                                // marginRight: "1rem",
                            }}
                        >
                            {(actions.length === 0 && withBackButton) && (
                                <Button
                                    color="info" 
                                    round 
                                    onClick={(e) => handleBackAction()}
                                    size="sm"
                                    width= '9rem'
                                    height= "2.35rem"
                                >
                                    <ArrowBackIcon className={classes.backIcon} />
                                    {intl.formatMessage({ id: 'pageHeader.back' })}
                                </Button>
                            )}

                        {actions.length > 0 && (
                            <div className= { visibleDropdown !== null ? 'dropDownVisible' : ''}
                                style={{
                                    position: "relative",
                                    display: "inline-flex",
                                    flexWrap: 'nowrap',
                                    gap: '5px',
                                    width: 'auto',
                                    overflowX: 'scroll',
                                    whiteSpace: 'nowrap',
                                    scrollbarWidth: 'none',
                                }}
                            >
                                {withBackButton && (
                                    <Button 
                                        size="lg" 
                                        color="info" 
                                        round 
                                        onClick={(e) => handleBackAction()}
                                        width= '9rem'
                                    >
                                        <ArrowBackIcon className={classes.backIcon} />
                                        {intl.formatMessage({ id: 'pageHeader.back' })}
                                    </Button>
                                )}
                                {actions.map((action, index) => action.component
                                    ?
                                    action.component
                                    :
                                    <div
                                        key={index}
                                        className= { visibleDropdown === null ? 'inline-block' : 'maxWidthDisplayNone' }
                                    >
                                        <Button
                                            disabled={action.disabled}
                                            key={index}
                                            size="sm"
                                            width= '11rem'
                                            round
                                            color={action.color || 'info'}
                                            onClick={action.onClick}
                                            style={{ 
                                                flexGrow: 0, flexShrink: 1, flexBasis: 'auto',
                                            }}
                                            
                                        >
                                            <span className={classes.backIcon}>{action.icon}</span>{action.title}
                                        </Button>
                                    </div>
                                )}

                                {dropDownBtn.length > 0 && (

                                    dropDownBtn.map((drop, index) => (
                                        <div key={index} 
                                            className= { (visibleDropdown === null || visibleDropdown === index) ? 'flex' : 'maxWidthDisplayNone' }
                                            style={{ position: 'relative',}}
                                        >
                                            <Button
                                                size="sm"
                                                width="11rem"
                                                round
                                                color={drop.color || 'info'}
                                                onClick={() => toggleDropdown(index)}
                                                style={{ 
                                                    flexGrow: 0, flexShrink: 1, flexBasis: 'auto', 
                                                }}
                                            >
                                                <span className={classes.backIcon}>{drop.icon}</span>{drop.title}
                                            </Button>

                                            {visibleDropdown === index && drop.subButtons && (
                                                <div
                                                className={`export-buttons ${
                                                    isClosing ? 'slide-up' : 'slide-down'
                                                }`}
                                                    style={{
                                                        position: 'absolute',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        width: '11rem',
                                                        top: '100%',
                                                        left: 0,
                                                        marginTop: '8px',
                                                        marginLeft: '5px',
                                                        gap: '8px',
                                                        zIndex: 10001,
                                                    }}
                                                >
                                                {drop.subButtons.map((subButton, subIndex) => (
                                                    <Button
                                                    key={subIndex}
                                                    size="sm"
                                                    round
                                                    color={subButton.color || 'info'}
                                                    onClick={() => {
                                                        subButton.onClick();
                                                        toggleDropdown(null);
                                                        }}
                                                        style={{ 
                                                            marginBottom: '0.5rem',
                                                        }}
                                                    >
                                                        {subButton.title}
                                                    </Button>
                                                ))}
                                                </div>
                                            )}
                                        </div>
                                    ))
                                )}

                            </div>
                        )}
                    </Grid>
                </>
            :   <>
                <Grid
                    style={{
                        display: 'flex', flexWrap: 'wrap',
                        gap: '5px', width: '100%', justifyContent: "space-between",
                        padding: "0rem 0.75rem 17px 9px",
                    }}
                >
                    <Grid item style={{ paddingRight: "0.5rem", width: "auto"}} >
                        <Typography variant="h5" className={classes.title} >{title}</Typography>
                        {subTitle && (
                            <Typography variant="body2" className={classes.subTitle}>{subTitle}</Typography>
                        )}
                    </Grid>
                    <Grid item
                        style={{ 
                            // paddingRight: '2rem', 
                            paddingTop: "0.2rem", marginRight: "1rem",
                            display: 'flex', justifyContent: 'flex-end',   
                            width: "auto", maxWidth: "100%",   
                        }}
                    >
                        {(actions.length === 0 && withBackButton) && (
                            // <div 
                            //     style={{
                            //         display: 'flex', justifyContent: 'flex-end', 
                            //         width: '100%',
                            //     }}
                            // >
                                <Button
                                    color="info" 
                                    round 
                                    onClick={(e) => handleBackAction()}
                                    size="sm"
                                    width= '9rem'
                                    height= "2.35rem"
                                >
                                    <ArrowBackIcon className={classes.backIcon} />
                                    {intl.formatMessage({ id: 'pageHeader.back' })}
                                </Button>
                            // </div>
                        )}
                        {actions.length > 0 && (
                            <div 
                                style={{ 
                                    // display: 'flex', justifyContent: 'flex-end', width: '100%', 
                                    position: "relative",
                                    display: "inline-block",
                                    // justifyContent: 'flex-end',
                                    flexWrap: 'nowrap',
                                    gap: '5px',
                                    width: 'auto',
                                    overflowX: 'scroll',
                                    whiteSpace: 'nowrap',
                                    scrollbarWidth: 'none',
                                }}
                            >
                                {withBackButton && (
                                    <Button 
                                        size="lg" 
                                        color="info" 
                                        round 
                                        onClick={(e) => handleBackAction()}
                                        width= '9rem'
                                    >
                                        <ArrowBackIcon className={classes.backIcon} />
                                        {intl.formatMessage({ id: 'pageHeader.back' })}
                                    </Button>
                                )}
                                {actions.map((action, index) => action.component
                                    ?
                                    action.component
                                    :
                                    <Button
                                        disabled={action.disabled}
                                        key={index}
                                        size="sm"
                                        width= '11rem'
                                        round
                                        color={action.color || 'info'}
                                        onClick={action.onClick}
                                    >
                                        <span className={classes.backIcon}>{action.icon}</span>{action.title}
                                    </Button>
                                )}
                            </div>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12} >{tabs}</Grid>
                </>
            }
        </Grid>


    )


    /*return (
        <div className={classes.pageHeaderWrapper} style={{zIndex: 10000}}>
            <Grid container spacing={3}>
                {withBackButton && (
                    <Grid item lg={1} md={2} sm={2} xs={4} style={{marginTop: "-13px", marginLeft: "-10px"}}>
                        <Button size="sm" color="facebook" round onClick={(e) => handleBackAction()}>
                            <ArrowBackIcon className={classes.backIcon} />
                            {intl.formatMessage({ id: 'pageHeader.back' })}
                        </Button>
                    </Grid>
                )}
                <Grid
                    item
                    xs={titleGridSizeXl}
                    lg={titleGridSizeLg}
                    md={titleGridSizeMd}
                    sm={9}
                >
                    <Typography variant="h5" className={classes.title}>{title}</Typography>
                    {subTitle && (
                        <p className={classes.subTitle}>{subTitle}</p>
                    )}
                </Grid>
                {actions.length > 0 && (
                    <Grid
                        item
                        xs={actionsGridSizeXl}
                        lg={actionsGridSizeLg}
                        md={actionsGridSizeMd}
                        sm={12}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            {actions.map((action, index) => action.component
                                ?
                                action.component
                                :
                                <Button
                                    key={index}
                                    size="sm"
                                    round
                                    color={action.color || 'info'}
                                    onClick={action.onClick}
                                >
                                    <span className={classes.backIcon}>{action.icon}</span>{action.title}
                                </Button>
                            )}
                        </div>
                    </Grid>
                )}
            </Grid>
        </div>
    )*/
};

export default injectIntl(PageHeader);