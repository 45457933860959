import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import DocumentsCard from './ClubCards/DocumentsCard';
import { useSnackbar } from 'notistack';
import { pathOr } from 'rambda';
import { injectIntl } from 'react-intl';
import PageHeader from 'components-lib/PageHeader/PageHeader';
import Loading from "components-lib/Loading/Loading";
import { Formik } from "formik";
import Button from "components/CustomButtons/Button.js";
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import ClubCard from './ClubCards/ClubCard';
import styles from "common/styles/widgets.js";
import { makeStyles } from '@material-ui/core/styles';
import DescriptionIcon from '@material-ui/icons/Description';
import InfoIcon from '@material-ui/icons/Info';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import LegalEntityCard from './ClubCards/LegalEntityCard';
import { getValidationSchemaClubDetail, getValidationSchemaClubCreateForeign } from "./validationSchema";
import EditIcon from '@material-ui/icons/Edit';
import Modal from "components-lib/Modal/Modal";
import { AntTabs, AntTab } from 'common/styles/muiDesign';
import CLUB_UPDATE from 'queries/ClubsQueries/clubUpdate';
import CLUB from 'queries/ClubsQueries/club';
import { generatePath } from "react-router-dom";
import paths from "paths";
import AddIcon from '@material-ui/icons/Add';
import CLUB_MEMBERS_BY_CLUB from 'queries/ClubsQueries/clubMembersByClub';
import PaymentCard from '../Payments/PaymentCards/PaymentCard';
import PaymentLicenseTableCard from '../Payments/PaymentCards/PaymentLicenseTableCard';
import REQUESTS_TABLE_BY_CLUB from 'queries/RequestsQueries/requestsTableByClub';
import IconCard from "components-lib/IconCard/IconCard";
import RequestTable from "../Requests/RequestTable";
import Badge from '@material-ui/core/Badge';
import CheckIcon from '@material-ui/icons/Check';
import BlockIcon from '@material-ui/icons/Block';
import { connect } from 'react-redux';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import RoleEnum from 'common/enums/RoleEnum'
import ClubTypesEnum from 'common/enums/ClubTypesEnum';
import { changeUserProfileAction } from 'redux/actions';
import PROFILE from 'queries/ProfileQueries/profile';
import { getApolloClient } from "configFiles/apollo";
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => styles(theme))

const ClubDetailPage = (props) => {
    const { intl, history, role, pendingRequestsNumber, changeUserProfileAction } = props;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const clubId = (props?.match?.params?.clubId) ? (props?.match?.params?.clubId) : location.state.clubId;
    var url_string = window.location.href
    var url = new URL(url_string);
    var tabFromUrl = Number(url.searchParams.get("tabFromUrl"));
    const client = getApolloClient(enqueueSnackbar, history, intl);
    const location = useLocation();

    const [readMode, setReadMode] = useState(true);
    const [validateParam, setValidateParam] = useState(true)
    const [modalOpen, setModalOpen] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [photo, setPhoto] = useState(null)
    const [renewLicense, setRenewLicense] = useState(false)
    const [clubMembers, setClubMembers] = useState(null)
    const [disableButton, setDisableButton] = useState(false);
    const [tabs, setTabs] = useState(0);
    const [type, setType] = useState(null)
    const { loading: loadingClubMembersData, data: clubMembersData } = useQuery(CLUB_MEMBERS_BY_CLUB, { variables: { clubId: clubId }, skip: role === RoleEnum.MEMBER });
    const { loading: loadingClub, data: dataClub, refetch: refetchClub } = useQuery(CLUB, { variables: { id: clubId } });
    const [clubUpdate] = useMutation(CLUB_UPDATE)
    const [refId, setRefId] = useState(null)

    useEffect(() => {
        if (location?.state?.type === 'PersonProfileChangeApproved') {
            setTabs(3)
        }
        if (location?.state?.refId) {
            setRefId(location?.state?.refId)
        }
    }, [location.state])

    const getUserProfileAction = async () => {
        try {
            const result = await client.query({
                query: PROFILE,
            });
            if (result.error) {
                throw result.error;
            }
            if (result.errors) {
                throw result.errors[0];
            }
            changeUserProfileAction(pathOr('', ['data'], result));

        } catch (error) {
            throw error;
        }
    };

    useEffect(() => {
        let presidentProfile = 0;
        let statutoryProfile = 0;
        let president = "";
        let statutory = "";
        setRenewLicense((pathOr('', ['club', 'licenses', 'next', 'request', 'status'], dataClub) === "Pending") || (pathOr('', ['club', 'licenses', 'current', 'request', 'status'], dataClub) === "Pending"))
        president = {
            presidentId: pathOr('', ['club', 'chairman', 'id'], dataClub),
            presidentEmail: pathOr('', ['club', 'chairman', 'person', 'profile', 'email'], dataClub),
            presidentFirstName: pathOr('', ['club', 'chairman', 'person', 'profile', 'firstName'], dataClub),
            presidentLastName: pathOr('', ['club', 'chairman', 'person', 'profile', 'lastName'], dataClub),
            presidentPhone: pathOr('', ['club', 'chairman', 'person', 'profile', 'phone'], dataClub),
        }
        statutory = {
            statutoryId: pathOr('', ['club', 'statutory', 'id'], dataClub),
            statutoryEmail: pathOr('', ['club', 'statutory', 'person', 'profile', 'email'], dataClub),
            statutoryFirstName: pathOr('', ['club', 'statutory', 'person', 'profile', 'firstName'], dataClub),
            statutoryLastName: pathOr('', ['club', 'statutory', 'person', 'profile', 'lastName'], dataClub),
            statutoryPhone: pathOr('', ['club', 'statutory', 'person', 'profile', 'phone'], dataClub),
        }

        setInitialValues({
            ...pathOr('', ['club', 'profile'], dataClub),
            ...statutory,
            ...president,
            members: Object.values(pathOr('', ['club', 'members'], dataClub))?.map((x, index) => { return index })?.length,
            isStatutoryPresident: (statutory?.[0]?.statutoryEmail === president?.[1]?.presidentEmail) ? true : false,
            bid: pathOr('', ['club', 'bid'], dataClub),
            type: pathOr('', ['club', 'type'], dataClub),
            licenses: pathOr('', ['club', 'licenses'], dataClub)
        })
    }, [dataClub]);

    useEffect(() => {
        setType(pathOr('', ['club', 'type'], dataClub))
        let clubMembersSelectItems = pathOr([], "clubMembersByClub.items", clubMembersData).map(
            (c) => {
                return {
                    value: c.id,
                    label: `${c?.person?.profile?.firstName} ${c?.person?.profile?.lastName} (${(c?.person?.profile?.email) ? c?.person?.profile?.email : "Neuvedený email"})`
                };
            }
        );
        setClubMembers(clubMembersSelectItems);
    }, [clubMembersData]);

    const getActions = () => {
        if (role !== RoleEnum.MEMBER)
            if (!renewLicense)
                return [{
                    title: intl.formatMessage({ id: 'clubs.detail.button.invite' }),
                    icon: <AddIcon />,
                    onClick: () => history.push({
                        pathname: `/admin${generatePath(paths.clubs.invite)}`,
                        state: {
                            name: pathOr('', ['club', 'profile', 'name'], dataClub),
                            id: pathOr('', ['club', 'id'], dataClub)
                        }
                    })
                }];
    };

    const getResult = (values) => {
        const result = { ...values };
        delete result.__typename;
        delete result.gdpr;
        delete result.logoUrl
        delete result.bid
        delete result.correspondenceAddress;
        delete result.description;
        delete result.presidentId;
        delete result.presidentEmail;
        delete result.presidentFirstName;
        delete result.presidentLastName;
        delete result.presidentPhone;
        delete result.presidentAddress1;
        delete result.presidentAddress2;
        delete result.presidentCity;
        delete result.presidentPostal;
        delete result.presidentCountry;
        delete result.statutoryId;
        delete result.statutoryEmail;
        delete result.statutoryFirstName;
        delete result.statutoryLastName;
        delete result.statutoryPhone;
        delete result.statutoryAddress1;
        delete result.statutoryAddress2;
        delete result.statutoryCity;
        delete result.statutoryPostal;
        delete result.statutoryCountry;
        delete result.statutoryDistrict;
        delete result.isStatutoryPresident;
        delete result.nationality;
        delete result.type;
        delete result.newPresident;
        delete result.newStatutory
        delete result.members;
        delete result.licenses;
        delete result.hasAttendedConference;
        return result;
    };

    const handleClubUpdate = (values) => {
        setDisableButton(true);
        const result = getResult(values)
        clubUpdate({
            variables: {
                id: clubId,
                input: {
                    type: values?.type,
                    profile: {
                        ...result,
                        logo: (photo) ? photo : undefined,
                    },
                    presidentId: (values?.newPresident) ? values?.newPresident : undefined,
                    statutoryId: (values?.newStatutory) ? values?.newStatutory : undefined

                }
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: 'clubs.update.snackbar.success' }), { variant: 'success' });
            refetchClub();
            getUserProfileAction();
            setReadMode(true);
            setDisableButton(false);
        }).catch((err) => {
            console.log('[Error]: ', err);
            setDisableButton(false);
        });
        setModalOpen(false);
    }

    const renderModalButtons = (formikProps) => {
        return (
            <>
                <Button
                    disabled={false}
                    onClick={() => handleClubUpdate(formikProps?.values)}
                    color="primary"
                    round
                    table
                    size="sm">
                    <EditIcon /> {intl.formatMessage({ id: 'clubs.update.modal.button' })}
                </Button>
            </>
        );
    };

    const handleChangeTab = (event, newValue) => {
        setTabs(newValue);
    };

    const handleFormValidation = (formikProps) => {
        formikProps.validateForm()
        if (formikProps.isValid) {
            formikProps.handleSubmit()
        } else if (!formikProps.isValid) {
            enqueueSnackbar((tabs === 0)
                ? intl.formatMessage({ id: 'clubs.update.invalidInput.info1' })
                : intl.formatMessage({ id: 'clubs.update.invalidInput.info2' }), { variant: 'warning' })
            formikProps.handleSubmit()
        } else
            return
    }

    const handleValidationSetReadMode = () => {
        refetchClub();
        setReadMode(false);
    }

    const getSendButton = (formikProps) => {
        //setValidateParam(true)
        return (
            <Box className={classes.floatRight} 
                style={{ 
                    width: '100%', display: 'flex', 
                    justifyContent: 'flex-end', flexWrap: 'wrap',
                }}
            >
            {(role !== RoleEnum.MEMBER) &&
                <>
                    < Button
                        color="primary"
                        size="sm"
                        disabled={disableButton}
                        round
                        marginTop= '0.6rem'
                        onClick={() => {
                            (role === RoleEnum.ADMIN)
                                ? (!readMode)
                                    ? handleFormValidation(formikProps)
                                    : handleValidationSetReadMode(formikProps)
                                : history.push({
                                    pathname: `/admin${generatePath(paths.requests.new)}`,
                                    state: dataClub,
                                })
                        }}
                    >
                        {(readMode)
                            ? <><EditIcon /> {intl.formatMessage({ id: 'button.editMode' })}</>
                            : <><CheckIcon /> {intl.formatMessage({ id: 'button.saveChanges' })}</>

                        }
                    </Button>
                    {!readMode &&
                        < Button
                            size="sm"
                            round
                            marginTop= '0.6rem'
                            onClick={() => { formikProps.resetForm(); setReadMode(readMode ? false : true) }}
                        >
                            <BlockIcon /> {intl.formatMessage({ id: 'button.cancelChanges' })}
                        </Button>
                    }
                </>
            }
        </Box >)
    }

    const handleBackAction = () => {
        history.goBack();
    };

    if (loadingClub || loadingClubMembersData) return <Loading />;
    return (<>
        <PageHeader
            withBackButton={ role !== RoleEnum.MEMBER ? true : false }
            handleBackAction={handleBackAction}
            actions={getActions()}
            title={pathOr('', ['club', 'profile', 'name'], dataClub)}
            subTitle={pathOr('', ['club', 'bid'], dataClub)}
            tabs={
                <AntTabs
                    onChange={handleChangeTab}
                    value={tabs}
                    variant="scrollable"
                >
                    <AntTab label={
                        <Typography variant="body1" >
                            <InfoIcon className={classes.tabsIcon} /> 
                                {/* {tabs === 0 && intl.formatMessage({ id: 'clubs.tab.clubCard' })} */}
                                {intl.formatMessage({ id: 'clubs.tab.clubCard' })}
                        </Typography>} />
                    <AntTab 
                        disabled={renewLicense} 
                        label={
                        <Typography variant="body1" >
                            <LocationCityIcon className={classes.tabsIcon} />
                            {/* {tabs === 1 && intl.formatMessage({ id: 'clubs.tab.legalEntityCard' })} */}
                            {intl.formatMessage({ id: 'clubs.tab.legalEntityCard' })}
                        </Typography>} />
                    {(role !== RoleEnum.MEMBER) && <AntTab disabled={renewLicense} label={<Typography variant="body1" ><DescriptionIcon className={classes.tabsIcon} />{intl.formatMessage({ id: 'clubs.tab.documentsCard' })}</Typography>} />}
                    {(role !== RoleEnum.MEMBER) && <AntTab disabled={renewLicense} label={<Typography variant="body1" >
                        {(role === RoleEnum.ADMIN || !pendingRequestsNumber)
                            ? <PlaylistAddCheckIcon className={classes.tabsIcon} />
                            : <Badge
                                badgeContent={pendingRequestsNumber}
                                color="secondary"
                                style={{ zIndex: 1000 }}
                                className={classes.badgeTabs} anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <PlaylistAddCheckIcon />
                            </Badge>
                        }
                        {intl.formatMessage({ id: 'clubs.tab.requestsCard' })}</Typography>} />
                    }
                </AntTabs>
            }
        />
        <Formik
            validateOnChange={true}
            validateOnBlur={true}
            enableReinitialize
            onSubmit={(values) => { handleClubUpdate(values) }}
            //initialValues={pathOr('', ['clubMember', 'isYouthWorker'], data)}
            initialValues={{
                ...pathOr('', ['club', 'profile'], dataClub),
                presidentId: pathOr('', ['club', 'chairman', 'id'], dataClub),
                presidentEmail: pathOr('', ['club', 'chairman', 'person', 'profile', 'email'], dataClub),
                presidentFirstName: pathOr('', ['club', 'chairman', 'person', 'profile', 'firstName'], dataClub),
                presidentLastName: pathOr('', ['club', 'chairman', 'person', 'profile', 'lastName'], dataClub),
                presidentPhone: pathOr('', ['club', 'chairman', 'person', 'profile', 'phone'], dataClub),
                statutoryId: pathOr('', ['club', 'statutory', 'id'], dataClub),
                statutoryEmail: pathOr('', ['club', 'statutory', 'person', 'profile', 'email'], dataClub),
                statutoryFirstName: pathOr('', ['club', 'statutory', 'person', 'profile', 'firstName'], dataClub),
                statutoryLastName: pathOr('', ['club', 'statutory', 'person', 'profile', 'lastName'], dataClub),
                statutoryPhone: pathOr('', ['club', 'statutory', 'person', 'profile', 'phone'], dataClub),
                members: Object.values(pathOr('', ['club', 'members'], dataClub))?.map((x, index) => { return index })?.length,
                isStatutoryPresident: (pathOr('', ['club', 'chairman', 'person', 'profile', 'email'], dataClub) === pathOr('', ['club', 'statutory', 'person', 'profile', 'email'], dataClub)) ? true : false,
                bid: pathOr('', ['club', 'bid'], dataClub),
                type: pathOr('', ['club', 'type'], dataClub),
                licenses: pathOr('', ['club', 'licenses'], dataClub),
                hasAttendedConference: pathOr('', ['club', 'hasAttendedConference'], dataClub)
            }}
            validationSchema={((pathOr('', ['club', 'type'], dataClub) === ClubTypesEnum.STANDARD_DOMESTIC || pathOr('', ['club', 'type'], dataClub) === ClubTypesEnum.UCI_DOMESTIC) ? getValidationSchemaClubDetail(intl) : getValidationSchemaClubCreateForeign(intl))}
        >
            {(formikProps) => (
                <Grid container spacing={1}>
                    {tabs === 0 && <>
                        <Grid item xs={12} lg={8}  style={{ overflowY: 'auto', paddingBottom: 'calc(50px + env(safe-area-inset-bottom))' }}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <ClubCard
                                        formikProps={formikProps}
                                        title={null}
                                        validateParam={validateParam}
                                        readMode={readMode}
                                        bid={pathOr('', ['club', 'bid'], dataClub)}
                                        issSyncedAt={pathOr('', ['club', 'issSyncedAt'], dataClub)}
                                        button={getSendButton(formikProps)}
                                        setPhoto={(newValue) => setPhoto(newValue)}
                                        photo={pathOr('', ['club', 'profile', 'logoUrl'], dataClub)}
                                        refetchClub={refetchClub}
                                        renewLicense={renewLicense}
                                        detail={true}
                                        clubId={clubId}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {(renewLicense) &&
                            <Grid item xs={12} lg={4}>
                                <PaymentLicenseTableCard
                                    formikProps={formikProps}
                                    data={(formikProps?.values?.licenses?.next?.request?.status === "Pending")
                                        ? pathOr('', ['club', 'licenses', 'next', 'request', 'payment'], dataClub)
                                        : pathOr('', ['club', 'licenses', 'current', 'request', 'payment'], dataClub)
                                    }
                                    returnButton={() => { formikProps.handleSubmit() }}
                                    view={0}
                                    detail="club"
                                />
                                <PaymentCard
                                    formikProps={formikProps}
                                    data={(formikProps?.values?.licenses?.next?.request?.status === "Pending")
                                        ? pathOr('', ['club', 'licenses', 'next', 'request', 'payment'], dataClub)
                                        : pathOr('', ['club', 'licenses', 'current', 'request', 'payment'], dataClub)
                                    }
                                    refetch={refetchClub}
                                    returnButton={() => { formikProps.handleSubmit() }}
                                    view={0}
                                    detail="memberLicenseAwaiting"
                                />
                            </Grid>
                        }
                    </>
                    }
                    {tabs === 1 && <>
                        <Grid item xs={12} 
                            lg={((formikProps?.values?.type === ClubTypesEnum.STANDARD_DOMESTIC) || (formikProps?.values?.type === ClubTypesEnum.UCI_DOMESTIC)) ? 12 : 8}
                            style={{ paddingBottom: 'calc(50px + env(safe-area-inset-bottom))' }} >
                            <LegalEntityCard
                                data={formikProps.values}
                                formikProps={formikProps}
                                newCompany={true}
                                title={null}
                                validateParam={validateParam}
                                readMode={readMode}
                                clubId={clubId}
                                setReadMode={(e) => setReadMode(readMode ? false : true)}
                                button={getSendButton(formikProps)}
                                options={clubMembers}
                                renewLicense={renewLicense}
                                refetchClub={refetchClub}
                                history={history}
                            />
                        </Grid>
                    </>
                    }
                    {tabs === 2 &&
                        <Grid item xs={12} lg={10} xl={10}>
                            <DocumentsCard
                                clubId={clubId}
                                data={formikProps.values}
                                formikProps={formikProps}
                                newCompany={true}
                                detail={true}
                                title={null}
                                validateParam={validateParam}
                                readMode={false}
                            />
                        </Grid>
                    }
                    {tabs === 3 &&
                        // <Grid item xs={12} lg={12}>
                        <Grid 
                            // item 
                            className="clubDetailRequestTable"
                            style={{ 
                                width: '100%', 
                                maxWidth: '75rem',
                            }}                        >
                            <IconCard>
                                <RequestTable
                                    query={REQUESTS_TABLE_BY_CLUB(clubId)}
                                    history={history}
                                    queryDataPath={['requestsTableByClub', 'items']}
                                    role={role}
                                    refId={ refId ? refId : null }
                                    clubId={clubId}
                                />
                            </IconCard>
                        </Grid>
                    }
                    <Modal
                        title={intl.formatMessage({ id: 'clubs.update.modal.title' })}
                        open={modalOpen}
                        onClose={() => setModalOpen(false)}
                        actions={renderModalButtons(formikProps)}
                        fullWidth
                    >
                        <Typography variant="body2" >{intl.formatMessage({ id: 'clubs.update.modal.body' })}</Typography>
                    </Modal>
                </Grid>
            )}
        </Formik >
    </>
    );
};

const mapStateToProps = (state) => ({
    role: state.role,
    pendingRequestsNumber: state.pendingRequestsNumber
});

const mapDispatchToProps = (dispatch) => ({
    changeUserProfileAction: (value) => dispatch(changeUserProfileAction(value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ClubDetailPage));

//544