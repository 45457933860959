import React, { useState, useEffect } from 'react';

import { generatePath } from "react-router-dom";
import paths from "paths";
import { pathOr } from 'rambda';

// redux
import { connect } from 'react-redux';
import { changePendingTransfersAndHostingNumberAction } from 'redux/actions';

// apollo
import { useQuery, useMutation } from '@apollo/client';
import { getApolloClient } from "configFiles/apollo";

import { injectIntl } from 'react-intl';

import { useSnackbar } from 'notistack';

import { Formik } from "formik";

// components
import PageHeader from 'components-lib/PageHeader/PageHeader';
import CheckIcon from '@material-ui/icons/Check';
import Modal from "components-lib/Modal/Modal";
import Grid from '@material-ui/core/Grid';
import Button from "components/CustomButtons/Button.js";
import MemberIdCard from './TransferCards/MemberIdCard';
import MemberClubCard from './TransferCards/MemberClubCard';
import MemberPersonalCard from './TransferCards/MemberPersonalCard';

// queries
import CLUBS from "queries/ClubsQueries/clubs";
import CLUB_TRANSFER_REQUEST_CREATE from "queries/TransferAndHostingQueries/clubTransferRequestCreate";
import CLUB_HOSTING_REQUEST_CREATE from "queries/TransferAndHostingQueries/clubHostingRequestCreate";
import REQUESTS_TABLE from 'queries/RequestsQueries/requestsTable';
import PROFILE from 'queries/ProfileQueries/profile';

// utils
import { getValidationSchemaTransfer, getValidationSchemaHosting } from "./validationSchema";
import RoleEnum from 'common/enums/RoleEnum'
import RequestTypesEnum from 'common/enums/RequestTypesEnum';
import TransfersAndHostingStatusesEnum from 'common/enums/TransfersAndHostingStatusesEnum';


const TransferNewPage = (props) => {
    const { intl, history, role, changePendingTransfersAndHostingNumberAction } = props;

    const { enqueueSnackbar } = useSnackbar();
    const client = getApolloClient(enqueueSnackbar, history, intl);
    
    const type = (window.location.pathname).includes('hosting') ? 'hosting' : 'transfer'
    
    const { data: userProfile } = useQuery(PROFILE);
    const { data: clubsData } = useQuery(CLUBS);
    const [clubTransferRequestCreate] = useMutation(CLUB_TRANSFER_REQUEST_CREATE)
    const [clubHostingRequestCreate] = useMutation(CLUB_HOSTING_REQUEST_CREATE)
    
    const [validateParam, setValidateParam] = useState(false)
    const [clubMemberPersonalData, setClubMemberPersonalData] = useState(null);
    const [clubMemberFunctionsData, setClubMemberFunctionsData] = useState([]);
    const [clubMemberDisciplinesData, setClubMemberDisciplinesData] = useState([]);
    const [clubMemberSourceClubData, setClubMemberSourceClubData] = useState([]);
    const [clubMemberTargetClubData, setClubMemberTargetClubData] = useState(null);

    const [currentClubs, setCurrentClubs] = useState([]);
    const [newClubId, setNewClubId] = useState(null);
    const [modalOpen, setModalOpen] = useState(null);
    const [disableButton, setDisableButton] = useState(false);


    useEffect(() => {
        let currentClubsSelectItems = pathOr([], "clubs.items", clubsData)?.map((item) => {
            return {
                value: item.id,
                label: item?.profile?.name
            }
        });

        /*let nextClubsSelectItems = pathOr([], "clubs.items", clubsData)?.map((item) => {
            return {
                value: item.id,
                label: item?.profile?.name
            }
        });*/
        setCurrentClubs(currentClubsSelectItems);
        //setNextClubs(nextClubsSelectItems);
    }, [clubsData]);

    const getPendingRequestsNumber = async () => {
        try {
            const result = await client.query({
                query: REQUESTS_TABLE,
                variables: {
                    "filter": [
                        {
                            "status": {
                                "in": [TransfersAndHostingStatusesEnum.AWAITING, TransfersAndHostingStatusesEnum.PAID]
                            },
                            "type": {
                                "in": [RequestTypesEnum.CLUB_TRANSFER, RequestTypesEnum.CLUB_HOSTING]
                            },
                        }
                    ]
                }
            });
            if (result.error) {
                throw result.error;
            }
            if (result.errors) {
                throw result.errors[0];
            }
            changePendingTransfersAndHostingNumberAction(pathOr('', ['data', 'requestsTable', 'totalCount'], result))
        } catch (error) {
            throw error;
        }
    }

    const handleRequestCreate = (values) => {
        const originalDateFrom = new Date(values?.values?.from);
        const updatedDateFrom = new Date(originalDateFrom);
        updatedDateFrom.setHours(0, 0, 0, 0);
        setDisableButton(true);
        if (type === 'transfer') {
            clubTransferRequestCreate({
                variables: {
                    input: {
                        from: updatedDateFrom,
                        memberId: clubMemberPersonalData?.id,
                        targetClubId: newClubId
                    }
                }
            }).then((response) => {
                enqueueSnackbar(intl.formatMessage({ id: 'transfers.snackbar.create.success' }), { variant: 'success' });
                setModalOpen(false);
                getPendingRequestsNumber();
                history.push(`/admin${generatePath(paths.transfers.detail, { requestId: response?.data?.clubTransferRequestCreate?.id })}`);
                setDisableButton(false);
            }).catch((err) => {
                console.log('[Error]: ', err);
                setDisableButton(false);
            });
        } else {
            const originalDateTo = new Date(values?.values?.to);
            const updatedDateTo = new Date(originalDateTo);
            updatedDateTo.setHours(23, 59, 59, 0);

            clubHostingRequestCreate({
                variables: {
                    input: {
                        from: updatedDateFrom,
                        to: updatedDateTo,
                        memberId: clubMemberPersonalData?.id,
                        targetClubId: newClubId
                    }
                }
            }).then((response) => {
                enqueueSnackbar(intl.formatMessage({ id: 'hosting.snackbar.create.success' }), { variant: 'success' });
                setModalOpen(false);
                history.push(`/admin${generatePath(paths.hosting.detail, { requestId: response?.data?.clubHostingRequestCreate?.id })}`);
                setDisableButton(false);
            }).catch((err) => {
                console.log('[Error]: ', err);
                setDisableButton(false);
            });
        }
        setModalOpen(false)
    }

    const renderModalButtons = (formikProps) => {
        return (
            <>
                <Button
                    disabled={disableButton}
                    onClick={() => handleRequestCreate(formikProps)}
                    color="primary"
                    round
                    table
                    size="sm">
                    <CheckIcon />  {intl.formatMessage({ id: 'transfersAndHosting.button.create' })}
                </Button>
            </>
        );
    };
    const handleSetMember = (clubMember) => {
        setClubMemberPersonalData(clubMember)
        setClubMemberFunctionsData(clubMember?.functions?.map((item) => {
            return {
                value: item?.function?.id,
                label: item?.function?.name,
            }
        }));

        let tempMemberDisciplines = [];
        let disc = (clubMember?.functions)?.find(item => item?.function?.id === '20' || item?.function?.id === '21')?.disciplines
        tempMemberDisciplines = disc?.map(item => {
            return {
                value: item?.id,
                label: item?.name,
            }
        })
        setClubMemberDisciplinesData(tempMemberDisciplines);

        setClubMemberSourceClubData(pathOr('', ['club'], clubMember))
        if (role === RoleEnum.STATUTORY || role === RoleEnum.PRESIDENT) {
            setClubMemberTargetClubData(pathOr('', ['me', 'member', 'club'], userProfile))
            setNewClubId(pathOr('', ['me', 'member', 'club', 'id'], userProfile))
        }
    }

    const handleBackAction = () => {
        history.goBack();
    };

    return (
        <Grid container spacing={1}>
            <PageHeader
                withBackButton={true}
                title={intl.formatMessage({ id: 'transfersAndHosting.new.title' }, [(type === 'hosting') ? intl.formatMessage({ id: 'hosting.hosting' }) : intl.formatMessage({ id: 'transfers.transfer' })])}
                handleBackAction={handleBackAction}
            />
            <Formik
                validateOnChange={true}
                validateOnBlur={true}
                enableReinitialize
                validationSchema={(type === 'transfer') ? getValidationSchemaTransfer(intl) : getValidationSchemaHosting(intl)}
                onSubmit={(values) => { setModalOpen(true) }}
                initialValues={{}}
            >
                {(formikProps) => (<>
                    <Grid item 
                        // xs={12} lg={8}
                    >
                        <Grid item xs={12}>
                            {/*<IconCard background={colors.disabledBackground} inside>*/}
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <MemberIdCard
                                        handleSetMember={(newValue) => handleSetMember(newValue)}
                                        validateParam={validateParam}
                                        formikProps={formikProps}
                                        type={type}
                                    />
                                    {clubMemberPersonalData &&
                                        <Formik
                                            enableReinitialize
                                            initialValues={{}}
                                        >
                                            {(fProps) => (<>
                                                <MemberPersonalCard
                                                    clubMemberPersonalData={clubMemberPersonalData}
                                                    clubMemberFunctionsData={clubMemberFunctionsData}
                                                    clubMemberDisciplinesData={clubMemberDisciplinesData}
                                                    role={role}
                                                />
                                                <MemberClubCard
                                                    clubMemberSourceClubData={clubMemberSourceClubData}
                                                    clubMemberTargetClubData={clubMemberTargetClubData}
                                                    setClubMemberTargetClubData={(newValue) => setClubMemberTargetClubData(newValue)}
                                                    setNewClubId={(newValue) => setNewClubId(newValue)}
                                                    clubs={currentClubs}
                                                    role={role}
                                                />
                                            </>
                                            )}
                                        </Formik>
                                    }

                                </Grid>

                                <Grid item xs={12} align="center">
                                    <br />
                                    {(clubMemberPersonalData && newClubId) && <>
                                        <Button
                                            align="center"
                                            color="primary"
                                            size="sm"
                                            round
                                            onClick={() => { setValidateParam(true); formikProps.handleSubmit() }}
                                        >
                                            <CheckIcon /> {intl.formatMessage({ id: 'transfersAndHosting.button.create' })}
                                        </Button>
                                    </>
                                    }
                                </Grid>

                            </Grid>
                            {/*</IconCard>*/}

                        </Grid>
                    </Grid>
                    <Modal
                        title={`${intl.formatMessage({ id: 'transfersAndHosting.modal.create.title' })} ${(type === 'hosting') ? intl.formatMessage({ id: 'hosting.hosting' }) : intl.formatMessage({ id: 'transfers.transfer' })}`}
                        open={modalOpen}
                        onClose={() => setModalOpen(false)}
                        actions={renderModalButtons(formikProps)}
                        fullWidth
                    >
                        {intl.formatMessage({ id: 'transfersAndHosting.modal.create.body' })} {(type === 'hosting') ? intl.formatMessage({ id: 'hosting.hosting' }) : intl.formatMessage({ id: 'transfers.transfer' })}?
                    </Modal>
                </>
                )}
            </Formik>
        </Grid >
    );
};

const mapStateToProps = (state) => ({
    role: state.role,
});

const mapDispatchToProps = (dispatch) => ({
    changePendingTransfersAndHostingNumberAction: (value) => dispatch(changePendingTransfersAndHostingNumberAction(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TransferNewPage));
